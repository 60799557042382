import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule
} from 'vuex-module-decorators'
import { AccountInfo } from '@azure/msal-browser'
import { Emitter } from '@/utilities/Emitter'
import axios from 'axios'

import store from '@/store/index'

export interface CreatePatientRequest {
  givenName: string
  surname: string
  displayName: string
  email: string
  type: string
  password: string
  token: string
}

export interface CreatedUser {
  '@odata.context': string
  id: string
  businessPhones: string[]
  displayName: string
  givenName: string
  jobTitle?: string
  mail: string
  mobilePhone?: string
  officeLocation?: string
  preferredLanguage?: string
  surname: string
  userPrincipalName: string
}

export interface CreatedUserResponse {
  user: CreatedUser
}

@Module({ name: 'auth', store: store, dynamic: true })
class Authentication extends VuexModule {
  account: AccountInfo | null = null
  token: string | null = null

  @Mutation
  setAccount(response: {
    account: AccountInfo | null
    token: string | null
    refresh?: boolean
  }) {
    this.account = response?.account ?? null
    this.token = response?.token ?? null

    if (response && !response.refresh) {
      if (this.account) {
        Emitter.emit('login')
      }
    } else if (!this.account) {
      Emitter.emit('logout')
    }
  }

  @Action
  async createPatientAccount(
    options: CreatePatientRequest
  ): Promise<CreatedUserResponse> {
    const endpoint = process.env.VUE_APP_CREATEUSER_ENDPOINT

    const response = await axios.post<CreatedUserResponse>(
      endpoint as string,
      options,
      {
        headers: {
          Authorization: `Bearer ${options.token}`
        }
      }
    )

    return response.data
  }
}

export default getModule(Authentication)
