<template>
  <div>
    <section class="app-header has-background-primary has-accent-bottom-border">
      <div class="app-header-start">
        <div class="brand-container">
          <router-link to="/">
            <img
              class="logo"
              src="/img/logo_sd.png"
              alt="South Dakota Medical Cannabis Registry"
            />
          </router-link>
        </div>
        <div class="brand-header">
          <p class="brand-title">South Dakota Medical Cannabis Program</p>
          <p class="brand-subtitle">{{ subtitle }}</p>
        </div>
      </div>
      <div class="app-header-end">
        <template v-if="notVerify">
          <b-button
            v-if="!$msal.isAuthenticated"
            type="is-light"
            icon-left="sign-in-alt"
            label="Login"
            @click="signIn"
            class="login-button"
            :loading="tokenLoad"
            :disabled="tokenLoad"
          />
          <template v-else>
            <b-dropdown
              append-to-body
              aria-role="menu"
              scrollable
              class="login-menu"
            >
              <template #trigger>
                <a class="navbar-item" role="button">
                  <span class="user-menu">
                    {{ authModule.account ? authModule.account.name : '' }}
                    <b-icon icon="caret-down" />
                  </span>
                </a>
              </template>
              <b-dropdown-item @click="resetPassword">
                Reset Password
              </b-dropdown-item>
              <b-dropdown-item @click="signOut">Logout</b-dropdown-item>
            </b-dropdown>
          </template>
        </template>
      </div>
    </section>
    <slot />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import AuthModule from '@/store/model/Authentication'

import PatientService from '@/services/PatientService'
import guid from '@/services/guid'

import store from '@/store'

import { Emitter } from '@/utilities/Emitter'

import * as Sentry from '@sentry/vue'
import { Caregiver, Patient } from '@/store/model/definitions/User'

import dayjs from 'dayjs'
import CaregiverService from '@/services/CaregiverService'

declare global {
  interface GlobalEventHandlersEventMap {
    swUpdated: CustomEvent<ServiceWorkerRegistration>
  }
}

@Component({})
export default class AppLayoutBase extends Vue {
  authModule = AuthModule

  updateExists = false
  refreshing = false
  registration: ServiceWorkerRegistration | null = null

  created() {
    document.addEventListener('swUpdated', this.refreshApp, { once: true })

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) {
          return
        }

        this.refreshing = true

        window.location.reload()
      })
    }

    Emitter.on('token-refresh', async () => {
      const claims = AuthModule.account?.idTokenClaims as {
        [key: string]: string
      }

      const paymentSuccess =
        typeof this.$route.query.SECURETOKENID !== 'undefined' &&
        this.$route.query.RESULT === '0'

      /* Handle Payflow in-process routing */
      if (typeof this.$route.query.SECURETOKENID !== 'undefined') {
        if (
          this.$route.query.RESULT !== '0' &&
          claims.extension_Type !== 'admin'
        ) {
          this.$router.replace({
            path: '/payment/landing',
            query: {
              paymentError: '1'
            }
          })

          return
        }

        /*  const userId = this.$route.query.USER1 as string
        const patient = (await PatientService.Fetch(userId)) as Patient

        const applications = patient.applications.sort((a, b) => {
          const aDate = dayjs(a.issueDate)
          const bDate = dayjs(b.issueDate)

          if (aDate.isSame(bDate)) {
            return 0
          }

          return bDate.isAfter(aDate) ? 1 : -1
        })

        if (this.$route.query.RESULT === '0') {
          // Successful Transaction
          if (typeof patient.payments === 'undefined') {
            patient.payments = []
          }

          let isDupe = false
          for (const payment of patient.payments) {
            if (payment.transaction === (this.$route.query.TXID as string)) {
              isDupe = true
              break
            }
          }

          if (!isDupe) {
            if (typeof this.$route.query.USER2 === 'undefined') {
              patient.enrollmentStatus = {
                name: 'pending',
                displayName: 'Pending'
              }

              if (typeof applications[0] !== 'undefined') {
                applications[0].status = {
                  name: 'pending',
                  displayName: 'Pending'
                }
              }
            }

            patient.payments!.push({
              applicationId:
                typeof applications[0] !== 'undefined'
                  ? applications[0].id
                  : guid(),
              orderData: this.$route.query.TENDER as string,
              transaction: this.$route.query.TXID as string,
              amount: parseFloat(this.$route.query.AMT as string),
              date: this.$route.query.TRANSTIME as string
            })

            await PatientService.Upsert({
              entity: patient
            })

            if (typeof this.$route.query.USER2 !== 'undefined') {
              const caregiver = (await CaregiverService.Fetch(
                this.$route.query.USER2 as string
              )) as Caregiver

              caregiver.enrollmentStatus = {
                name: 'pending',
                displayName: 'Pending'
              }

              await CaregiverService.Upsert({
                entity: caregiver
              })

              this.$router.replace({
                path: '/patient/dashboard',
                query: {
                  caregiver: this.$route.query.USER2 as string
                }
              })

              return
            }
          }
        } else {
          // Transaction not successful
          patient.enrollmentStatus = {
            name: 'payment error',
            displayName: 'Payment Error'
          }

          if (typeof this.$route.query.USER2 === 'undefined') {
            if (typeof applications[0] !== 'undefined') {
              applications[0].status = {
                name: 'payment error',
                displayName: 'Payment Error'
              }
            }
          }

          await PatientService.Upsert({
            entity: patient
          })

          if (claims.extension_Type !== 'admin') {
            this.$router.replace({
              path: '/payment/landing',
              query: {
                paymentError: '1'
              }
            })

            return
          }
        }*/
      }

      if (AuthModule.account) {
        Sentry.setUser({
          email: AuthModule.account.username,
          id: AuthModule.account.localAccountId
        })
      } else {
        Sentry.configureScope(scope => scope.setUser(null))
      }

      switch (claims.extension_Type) {
        case 'practitioner':
          this.$router.replace('/practitioner/dashboard')
          break

        case 'patient':
          if (paymentSuccess) {
            this.$router.replace({
              path: '/patient/dashboard',
              query: {
                paymentSuccess: '1'
              }
            })
          } else {
            this.$router.replace('/patient/dashboard')
          }
          break

        case 'admin':
          this.$router.replace('/admin/dashboard')
          break

        case 'caregiver':
          this.$router.replace('/caregiver/dashboard')
          break

        default:
          break
      }
    })
  }

  get tokenLoad(): boolean {
    return store.state.tokenLoad
  }

  get notVerify(): boolean {
    return location.hostname !== 'medcannabisverify.sd.gov'
  }

  get subtitle(): string {
    return this.$route.meta?.subtitle ?? ''
  }

  updateAvailable(event: CustomEvent<ServiceWorkerRegistration>): void {
    this.registration = event.detail
    this.updateExists = true

    this.$buefy.snackbar.open({
      message: 'A new version of the Registry is ready!',
      indefinite: true,
      type: 'is-success',
      position: 'is-bottom-right',
      queue: false,
      actionText: 'Update',
      onAction: () => {
        this.refreshApp(event)
      }
    })
  }

  refreshApp(event: CustomEvent<ServiceWorkerRegistration>): void {
    this.registration = event.detail
    this.updateExists = false

    if (!this.registration || !this.registration.waiting) {
      return
    }

    this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
  }

  async signIn(): Promise<void> {
    await this.$msal.signIn()
    this.$router.go(0)
  }

  async signOut(): Promise<void> {
    await this.$msal.signOut()
    window.location.assign('/')
  }

  async resetPassword(): Promise<void> {
    await this.$msal.resetPassword()
  }
}
</script>

<style lang="scss" scoped>
.dropdown > .dropdown-trigger > .navbar-item {
  color: #fff !important;

  &:hover {
    color: #003865 !important;
  }
}

.user-menu {
  white-space: nowrap;
}

.app-header {
  display: flex;
  justify-content: space-between;

  .app-header-start {
    display: flex;
    align-items: center;
  }

  .app-header-end {
    display: flex;
    flex-direction: column-reverse;

    .login-button {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .login-menu {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .brand-header {
    display: flex;
    flex-direction: column;
    color: #fff;
    padding: 1.5em;

    .brand-title {
      font-size: 2rem;
      font-weight: 600;
      line-height: 1.125;
      margin-bottom: 1.5rem;
      color: #fff;
    }

    .brand-subtitle {
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.25;
      margin-top: -1.25rem;
      color: #fff;
    }
  }

  .brand-container {
    padding: 10px;
    width: 200px;
    min-width: 200px;
    background: #fff;
    height: 100%;

    .logo {
      width: 180px;
      height: 115px;
    }
  }

  @media screen and (max-width: 768px) {
    .brand-container {
      display: none !important;
    }

    .brand-title {
      font-size: 1.5rem !important;
    }

    .brand-subtitle {
      font-size: 1rem !important;
    }
  }
}
</style>
