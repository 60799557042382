import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

import AuthModule from '@/store/model/Authentication'

const routes: Array<RouteConfig> = [
  {
    path:
      location.hostname === 'medcannabisverify.sd.gov' ? '/:cardnumber?' : '/',
    name: 'Home',
    component: () => {
      if (location.hostname === 'medcannabisverify.sd.gov') {
        return import(/* webpackChunkName: "verify" */ '../views/Verify.vue')
      } else {
        return import(/* webpackChunkName: "home" */ '../views/Home.vue')
      }
    },
    meta: {
      layout: 'Base',
      subtitle:
        location.hostname === 'medcannabisverify.sd.gov'
          ? 'Card Verification'
          : 'Login'
    }
  },
  {
    path: '/verify/:cardnumber?',
    name: 'Verify',
    component: () =>
      import(/* webpackChunkName: "verify" */ '../views/Verify.vue'),
    meta: {
      layout: 'Base',
      subtitle: 'Card Verification'
    }
  },
  {
    path: '/practitioner/enrollment',
    name: 'PractitionerEnrollment',
    component: () =>
      import(
        /* webpackChunkName: "practitionerEnrollment" */ '../views/PractitionerEnrollment.vue'
      ),
    meta: {
      layout: 'Base',
      subtitle: 'Healthcare Practitioner Registration'
    }
  },
  {
    path: '/patient/enrollment',
    name: 'PatientEnrollment',
    component: () =>
      import(
        /* webpackChunkName: "patientEnrollment" */ '../views/PatientEnrollment.vue'
      ),
    meta: {
      layout: 'Base',
      requiresAuth: true,
      subtitle: 'Patient Application'
    }
  },
  {
    path: '/patient/nonresident/enrollment',
    name: 'NonResidentPatientEnrollment',
    component: () =>
      import(
        /* webpackChunkName: "nonResidentPatientEnrollment" */ '../views/NonResidentPatientEnrollment.vue'
      ),
    meta: {
      layout: 'Base',
      requiresAuth: true,
      subtitle: 'Non-Resident Patient Application'
    }
  },
  {
    path: '/caregiver/enrollment/:patientId?',
    name: 'CaregiverEnrollment',
    component: () =>
      import(
        /* webpackChunkName: "caregiverEnrollment" */ '../views/CaregiverEnrollment.vue'
      ),
    meta: {
      layout: 'Base',
      requiresAuth: true,
      subtitle: 'Caregiver Application'
    }
  },
  {
    path: '/patient/dashboard',
    name: 'PatientDashboard',
    component: () =>
      import(
        /* webpackChunkName: "patientDashboard" */ '../views/PatientDashboard.vue'
      ),
    meta: {
      requiresAuth: true,
      layout: 'Base',
      subtitle: 'Patient Dashboard'
    }
  },
  {
    path: '/payment/landing',
    name: 'PaymentLanding',
    component: () =>
      import(
        /* webpackChunkName: "paymentLanding" */ '../views/PaymentLanding.vue'
      ),
    meta: {
      requiresAuth: true,
      layout: 'Base',
      subtitle: 'Fee Payment'
    }
  },
  {
    path: '/caregiver/dashboard',
    name: 'CaregiverDashboard',
    component: () =>
      import(
        /* webpackChunkName: "caregiverDashboard" */ '../views/CaregiverDashboard.vue'
      ),
    meta: {
      requiresAuth: true,
      layout: 'Base',
      subtitle: 'Caregiver Dashboard'
    }
  },
  {
    path: '/practitioner/dashboard',
    name: 'PractitionerDashboard',
    component: () =>
      import(
        /* webpackChunkName: "practitionerDashboard" */ '../views/PractitionerDashboard.vue'
      ),
    meta: {
      layout: 'Base',
      requiresAuth: true,
      subtitle: 'Healthcare Practitioner Dashboard'
    }
  },
  {
    path: '/admin/dashboard',
    name: 'AdminDashboard',
    component: () =>
      import(
        /* webpackChunkName: "adminDashboard" */ '../views/AdminDashboard.vue'
      ),
    meta: {
      layout: 'Base',
      requiresAuth: true,
      subtitle: 'Administration Dashboard'
    }
  },
  {
    path: '/admin/dashboard/:show',
    name: 'AdminDashboardShow',
    component: () =>
      import(
        /* webpackChunkName: "adminDashboard" */ '../views/AdminDashboard.vue'
      ),
    meta: {
      layout: 'Base',
      requiresAuth: true,
      subtitle: 'Administration Dashboard'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // Before every route change, check if route requires auth and redirect if not logged in.
  if (to.meta && to.meta.requiresAuth && !AuthModule.account) {
    next({
      name: 'Home'
    })
    return
  }

  // If we're logged in, check what type of user we are and route accordingly.
  if (AuthModule.account) {
    const claims = AuthModule.account.idTokenClaims as {
      [key: string]: string
    }

    switch (claims.extension_Type) {
      // If a practitioner is going somewhere other than the dashboard or enrollment routes,
      // route them to their dashboard. Otherwise, allow the route.
      case 'practitioner':
        if (
          to.name !== 'PractitionerDashboard' &&
          to.name !== 'PractitionerEnrollment'
        ) {
          next({
            name: 'PractitionerDashboard'
          })
        } else {
          next()
        }
        break

      // If a patient is going somewhere other than the dashboard or enrollment routes,
      // route them to their dashboard. Otherwise, allow the route.
      case 'patient':
        if (
          to.name !== 'PatientDashboard' &&
          to.name !== 'PatientEnrollment' &&
          to.name !== 'NonResidentPatientEnrollment' &&
          to.name !== 'PaymentLanding'
        ) {
          next({
            name: 'PatientDashboard'
          })
        } else {
          next()
        }
        break

      // Route admins to their dashboard.
      case 'admin':
        if (to.name !== 'AdminDashboard' && to.name !== 'AdminDashboardShow') {
          next({
            name: 'AdminDashboard'
          })
        } else {
          next()
        }
        break

      // If a caregiver is going somewhere other than the dashboard or enrollment routes,
      // route them to their dashboard. Otherwise, allow the route.
      case 'caregiver':
        if (
          to.name !== 'CaregiverDashboard' &&
          to.name !== 'CaregiverEnrollment'
        ) {
          next({
            name: 'CaregiverDashboard'
          })
        } else {
          next()
        }
        break

      // Shouldn't ever get here but if we do, allow the route.
      default:
        next()
        break
    }
  } else {
    // We're not logged in, and the route doesn't require authentiction. Allow the route.
    next()
  }
})

export default router
