import Vue, { PluginObject, VueConstructor } from 'vue'

import axios from 'axios'
import AuthModule from '@/store/model/Authentication'

import * as Sentry from '@sentry/vue'

declare module 'vue/types/vue' {
  interface Vue {
    $search: QueryPlugin
  }
}

export interface QueryPluginOptions {
  endpoint: string
}

export interface QueryRequest {
  query: string
  container: string
}

interface QueryResponse<T> {
  hasMoreResults: boolean
  headers: Record<string, string | number>
  resources: T[]
  length: number
}

export let queryPluginInstance: QueryPlugin
export class QueryPlugin implements PluginObject<QueryPluginOptions> {
  private endpoint = ''

  public install(vue: VueConstructor<Vue>, options?: QueryPluginOptions): void {
    if (!options) {
      throw new Error('QueryPluginOptions must be specified')
    }

    this.endpoint = options.endpoint
    queryPluginInstance = this
    vue.prototype.$search = Vue.observable(queryPluginInstance)
  }

  public async query<T>(request: QueryRequest): Promise<T[]> {
    let serviceResponse: T[] = []

    try {
      const response = await axios.post<QueryResponse<T>>(
        this.endpoint,
        request,
        {
          headers: {
            Authorization: `Bearer ${AuthModule.token}`
          }
        }
      )

      serviceResponse = response.data.resources
    } catch (error) {
      Sentry.captureException(error)
    }

    return serviceResponse
  }
}
