<template>
  <component :is="layout">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </component>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

import AppLayoutBase from '@/layouts/AppLayoutBase.vue'
import AppLayoutDefault from '@/layouts/AppLayoutDefault.vue'

@Component({})
export default class App extends Vue {
  get layout(): AppLayoutBase | typeof AppLayoutDefault {
    switch (this.$route.meta?.layout) {
      case 'Base':
        return AppLayoutBase

      default:
        return AppLayoutDefault
    }
  }
}
</script>
