import * as msal from '@azure/msal-browser'
import Vue, { PluginObject, VueConstructor } from 'vue'

import { MsalNavClient } from './MsalNavClient'

import store from '../store/index'
import { Emitter } from './Emitter'

import * as Sentry from '@sentry/vue'

import router from '@/router'

declare module 'vue/types/vue' {
  interface Vue {
    $msal: MsalPlugin
  }
}

export interface MsalPluginOptions {
  clientId: string
  loginAuthority: string
  passwordAuthority: string
  knownAuthority: string
  signupAuthority: string
}

let msalInstance: msal.PublicClientApplication
let authResult: msal.AuthenticationResult | undefined

export let msalPluginInstance: MsalPlugin

export class MsalPlugin implements PluginObject<MsalPluginOptions> {
  private pluginOptions: MsalPluginOptions = {
    clientId: '',
    loginAuthority: '',
    passwordAuthority: '',
    knownAuthority: '',
    signupAuthority: ''
  }

  public isAuthenticated = false

  public install(vue: VueConstructor<Vue>, options?: MsalPluginOptions): void {
    if (!options) {
      throw new Error('MsalPluginOptions must be specified')
    }
    this.pluginOptions = options
    this.initialize(options)
    msalPluginInstance = this
    vue.prototype.$msal = Vue.observable(msalPluginInstance)
  }

  private initialize(options: MsalPluginOptions) {
    const msalConfig: msal.Configuration = {
      auth: {
        clientId: options.clientId,
        authority: options.loginAuthority,
        knownAuthorities: [options.knownAuthority],
        redirectUri: `${location.origin}/login.html`
      },
      cache: {
        cacheLocation: 'sessionStorage'
      },
      system: {
        loggerOptions: {
          loggerCallback: (
            level: msal.LogLevel,
            message: string,
            containsPii: boolean
          ): void => {
            if (containsPii) {
              return
            }
            switch (level) {
              case msal.LogLevel.Error:
                console.error(message)
                return
              case msal.LogLevel.Info:
                console.info(message)
                return
              case msal.LogLevel.Verbose:
                console.debug(message)
                return
              case msal.LogLevel.Warning:
                console.warn(message)
                return
            }
          },
          piiLoggingEnabled: true,
          logLevel: msal.LogLevel.Error
        }
      }
    }
    msalInstance = new msal.PublicClientApplication(msalConfig)
    msalInstance.setNavigationClient(new MsalNavClient(router))

    store.state.tokenLoad = true

    msalInstance.handleRedirectPromise().then(() => {
      this.acquireApiToken().then(() => {
        store.commit('setAccount', {
          account: authResult?.account ?? undefined,
          token: authResult?.idToken ?? undefined
        })

        if (authResult) {
          this.isAuthenticated = true
        }

        store.state.tokenLoad = false
        Emitter.emit('token-refresh')
      })
    })
  }

  public async signIn(): Promise<void> {
    try {
      const loginRequest: msal.PopupRequest = {
        scopes: ['openid', 'offline_access']
      }
      await msalInstance.loginPopup(loginRequest)
    } catch (err: any) {
      // handle error
      if (err.errorMessage && err.errorMessage.indexOf('AADB2C90118') > -1) {
        try {
          const passwordResetResponse: msal.AuthenticationResult =
            await msalInstance.loginPopup({
              scopes: ['openid', 'offline_access'],
              authority: this.pluginOptions.passwordAuthority
            })
          this.isAuthenticated = !!passwordResetResponse.account
        } catch (passwordResetError) {
          console.error(passwordResetError)
        }
      } else {
        this.isAuthenticated = false
      }
    }
  }

  public async signOut(): Promise<void> {
    await msalInstance.logoutPopup()
    store.commit('setAccount', null)
    this.isAuthenticated = false
  }

  public async resetPassword(): Promise<void> {
    try {
      await msalInstance.loginPopup({
        scopes: ['openid', 'offline_access'],
        authority: this.pluginOptions.passwordAuthority
      })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  public async signUp(): Promise<void> {
    try {
      await msalInstance.loginPopup({
        scopes: ['openid', 'offline_access'],
        authority: this.pluginOptions.signupAuthority
      })

      store.commit('setAccount', null)
      this.isAuthenticated = false

      await msalInstance.logoutPopup({ postLogoutRedirectUri: '/?logout=1' })
    } catch (err) {
      // handle error
      console.error(err)
    }
  }

  public async acquireApiToken(): Promise<string | undefined> {
    const account = msalInstance.getAllAccounts()[0]

    if (!account) {
      return
    }

    const request = {
      account,
      scopes: ['openid', 'offline_access']
    }

    try {
      authResult = await msalInstance.acquireTokenSilent(request)

      return authResult.idToken
    } catch (error) {
      if (error instanceof msal.InteractionRequiredAuthError) {
        try {
          authResult = await msalInstance.acquireTokenPopup(request)
          return authResult.idToken
        } catch (poperror) {
          await msalInstance.logoutPopup({
            postLogoutRedirectUri: '/'
          })
        }
      } else {
        await msalInstance.logoutPopup({
          postLogoutRedirectUri: '/'
        })
      }
    }
  }

  public getInstance(): typeof msalInstance {
    return msalInstance
  }
}
