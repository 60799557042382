import Vue from 'vue'
import App from './App.vue'

import store from '@/store/index'
import router from '@/router/index'

import './registerServiceWorker'

import Buefy from 'buefy'
import '@/scss/buefy.scss'

import * as Sentry from '@sentry/vue'

import VueMask from 'v-mask'
import VueBarcode from '@chenfengyuan/vue-barcode'
import StoragePlugin from 'vue-web-storage'

import { MsalPlugin, msalPluginInstance } from './utilities/MsalPlugin'
import { FileStoragePlugin } from './utilities/FileStoragePlugin'
import { QueryPlugin } from './utilities/QueryPlugin'

import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faAlignJustify,
  faChevronSquareDown,
  faSlidersH,
  faToggleOn,
  faAnalytics,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowUp,
  faArrows,
  faAt,
  faBug,
  faCabinetFiling,
  faCalendar,
  faCalendarAlt,
  faCalendarExclamation,
  faCalendarWeek,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretSquareDown,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronLeft,
  faChevronRight,
  faClipboardList,
  faClone,
  faClock,
  faCode,
  faCog,
  faColumns,
  faComments,
  faCopy,
  faCrop,
  faCreditCard,
  faDesktop,
  faDrawCircle,
  faDollarSign,
  faDotCircle,
  faEdit,
  faEnvelope,
  faExclamationTriangle,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileUser,
  faFileWord,
  faFilePdf,
  faFilter,
  faFolder,
  faFolders,
  faFolderTree,
  faFont,
  faGripHorizontal,
  faGripLinesVertical,
  faHeading,
  faHome,
  faHorizontalRule,
  faImage,
  faInfo,
  faInfoCircle,
  faKey,
  faLandmark,
  faLayerGroup,
  faLayerMinus,
  faLayerPlus,
  faLightbulb,
  faLink,
  faList,
  faLockAlt,
  faMarker,
  faMinus,
  faPalette,
  faPhone,
  faPlus,
  faPrint,
  faQuestionCircle,
  faQuestion,
  faRectangleWide,
  faRedo,
  faRoute,
  faSave,
  faSearch,
  faShapes,
  faShare,
  faShoePrints,
  faSignature,
  faSignOut,
  faSitemap,
  faSpinner,
  faStar,
  faTachometerAlt,
  faTag,
  faTags,
  faTasks,
  faText,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faUndo,
  faUpload,
  faUserFriends,
  faUser,
  faUserPlus,
  faUserMinus,
  faUserTimes,
  faUserEdit,
  faUsers,
  faUsdSquare,
  faCalendarStar,
  faCircle,
  faSquare,
  faChevronDown,
  faCompress,
  faExpand,
  faPercent,
  faDownload,
  faExpandArrows,
  faArrowsH,
  faHandPointer,
  faSignInAlt,
  faUserCheck,
  faUserMd,
  faFileSearch,
  faClipboardUser,
  faUserClock,
  faHospital,
  faArrowAltRight,
  faPlay,
  faPause,
  faStop,
  faArrowAltCircleUp,
  faPencil,
  faPlusCircle,
  faMinusCircle,
  faHistory,
  faBriefcaseMedical,
  faPills,
  faSpa,
  faWindowClose,
  faExternalLink,
  faMoneyCheckAlt,
  faNotes
} from '@fortawesome/pro-light-svg-icons'

import {
  faCircleCheck as fasCircleCheck,
  faExclamationTriangle as fasExclamationTriangle,
  faBell as fasBell,
  faCheck as fasCheck,
  faPlus as fasPlus,
  faCircleExclamation as fasCircleExclamation
} from '@fortawesome/pro-solid-svg-icons'

import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from '@fortawesome/vue-fontawesome'

Vue.config.productionTip = false

library.add(
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faAlignJustify,
  faUserEdit,
  faChevronSquareDown,
  faSlidersH,
  faToggleOn,
  faAnalytics,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowUp,
  faArrows,
  faAt,
  faBug,
  faCabinetFiling,
  faCalendar,
  faCalendarAlt,
  faCalendarExclamation,
  faCalendarWeek,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretSquareDown,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronLeft,
  faChevronRight,
  faClipboardList,
  faCreditCard,
  faMoneyCheckAlt,
  faClone,
  faClock,
  faCode,
  faCog,
  faColumns,
  faComments,
  faCopy,
  faCrop,
  faDesktop,
  faDrawCircle,
  faDollarSign,
  faDotCircle,
  faEdit,
  faEnvelope,
  faExclamationTriangle,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileUser,
  faFileWord,
  faFilePdf,
  faFilter,
  faFolder,
  faFolders,
  faFolderTree,
  faFont,
  faGripHorizontal,
  faGripLinesVertical,
  faHeading,
  faHome,
  faHorizontalRule,
  faImage,
  faInfo,
  faInfoCircle,
  faKey,
  faLandmark,
  faLayerGroup,
  faLayerMinus,
  faLayerPlus,
  faLightbulb,
  faLink,
  faList,
  faLockAlt,
  faMarker,
  faMinus,
  faPalette,
  faPhone,
  faPlus,
  faPrint,
  faQuestionCircle,
  faQuestion,
  faRectangleWide,
  faRedo,
  faRoute,
  faSave,
  faSearch,
  faShapes,
  faShare,
  faShoePrints,
  faSignature,
  faSignOut,
  faSitemap,
  faSpinner,
  faStar,
  faTachometerAlt,
  faTag,
  faTags,
  faTasks,
  faText,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faUndo,
  faUpload,
  faUserFriends,
  faUser,
  faUserPlus,
  faUserMinus,
  faUsers,
  faUsdSquare,
  faCalendarStar,
  faCircle,
  faSquare,
  faChevronDown,
  faCompress,
  faExpand,
  faPercent,
  faDownload,
  faExpandArrows,
  faArrowsH,
  faHandPointer,
  faSignInAlt,
  faUserCheck,
  faUserMd,
  faFileSearch,
  faClipboardUser,
  faUserClock,
  faUserCheck,
  faUserFriends,
  faHospital,
  faArrowAltRight,
  faDollarSign,
  faPlay,
  faPause,
  faStop,
  faArrowAltCircleUp,
  faPencil,
  faStar,
  faArrowAltCircleUp,
  faPlusCircle,
  faMinusCircle,
  faGripLinesVertical,
  faHistory,
  faBriefcaseMedical,
  faPills,
  faSpa,
  faUserTimes,
  faWindowClose,
  faExternalLink,
  faUserTimes,
  faTimesCircle,
  faNotes,
  fasCircleCheck,
  fasExclamationTriangle,
  fasBell,
  fasCheck,
  fasPlus,
  fasCircleExclamation
)

Vue.use(VueMask)
Vue.use(new MsalPlugin(), {
  clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
  loginAuthority: process.env.VUE_APP_MSAL_LOGIN_AUTHORITY,
  passwordAuthority: process.env.VUE_APP_MSAL_PASSWORD_RESET_AUTHORITY,
  knownAuthority: process.env.VUE_APP_MSAL_KNOWN_AUTHORITY,
  signupAuthority: process.env.VUE_APP_MSAL_SIGNUP_AUTHORITY
})
Vue.use(new FileStoragePlugin())
Vue.use(new QueryPlugin(), {
  endpoint: process.env.VUE_APP_QUERY_ENDPOINT
})
Vue.use(StoragePlugin, {
  prefix: 'sdmc',
  drivers: ['local']
})

Vue.use(FloatingVue)

Vue.component('vue-barcode', VueBarcode)
Vue.component('fa', FontAwesomeIcon)
Vue.component('fa-layers', FontAwesomeLayers)
Vue.component('fa-layers-text', FontAwesomeLayersText)

Vue.use(Buefy, {
  defaultProgrammaticPromise: true,
  defaultIconPack: 'fal',
  defaultIconComponent: 'fa'
})

const msal = msalPluginInstance.getInstance()
const accounts = msal.getAllAccounts()

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  release: process.env.VUE_APP_RELEASE
    ? process.env.VUE_APP_RELEASE
    : undefined,
  environment:
    process.env.NODE_ENV !== 'production'
      ? 'development'
      : process.env.VUE_APP_ENVIRONMENT,
  logErrors: process.env.NODE_ENV !== 'production',
  initialScope:
    accounts.length > 0
      ? {
          user: {
            email: accounts[0].username,
            id: accounts[0].localAccountId
          }
        }
      : undefined,
  integrations: [],
  tracesSampleRate: 1.0,
  trackComponents: true,

  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === 'xhr' && hint) {
      const data = {
        requestBody: hint.xhr.__sentry_xhr__?.body ?? '',
        response: hint.xhr.response,
        responseUrl: hint.xhr.responseURL
      }

      return { ...breadcrumb, data }
    }

    return breadcrumb
  }
})

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
