import { NavigationClient, NavigationOptions } from '@azure/msal-browser'
import VueRouter from 'vue-router'

export class MsalNavClient extends NavigationClient {
  private router: VueRouter

  constructor(router: VueRouter) {
    super()
    this.router = router
  }

  async navigateInternal(
    url: string,
    options: NavigationOptions
  ): Promise<boolean> {
    const relativePath = url.replace(window.location.origin, '')

    if (options.noHistory) {
      this.router.replace(relativePath)
    } else {
      this.router.push(relativePath)
    }

    return false
  }
}
