import * as storage from '@azure/storage-blob'
import Vue, { PluginObject, VueConstructor } from 'vue'
import * as Sentry from '@sentry/vue'

import axios from 'axios'

import AuthModule from '@/store/model/Authentication'

declare module 'vue/types/vue' {
  interface Vue {
    $storage: FileStoragePlugin
  }
}

export interface FileStoragePluginOptions {
  connection: string
}

export interface FileStoragePluginUploadOptions {
  name: string
  data: Blob
  id?: string
}

export interface FileStoragePluginGetFolderOptions {
  name: string
  id?: string
}

export interface FileStoragePluginDeleteOptions {
  name: string
  id?: string
}

interface StorageToken {
  sasKey: string
  url: string
}

export let fileStoragePluginInstance: FileStoragePlugin

export class FileStoragePlugin
  implements PluginObject<FileStoragePluginOptions>
{
  private token: StorageToken | undefined

  public install(vue: VueConstructor<Vue>): void {
    this.initialize().then(() => {
      fileStoragePluginInstance = this
      vue.prototype.$storage = Vue.observable(fileStoragePluginInstance)
    })
  }

  private async initialize() {
    this.refreshToken()
  }

  async refreshToken(retry?: boolean) {
    if (AuthModule.token) {
      try {
        const tokenResponse = await axios.get<StorageToken>(
          `${process.env.VUE_APP_STORAGE_ENDPOINT}?token=${AuthModule.token}`
        )
        this.token = tokenResponse.data
      } catch (error) {
        if (!retry) {
          this.refreshToken(true)
        } else {
          Sentry.captureException(error)
        }
      }
    }
  }

  async upload(
    request: FileStoragePluginUploadOptions,
    options: storage.BlockBlobParallelUploadOptions = {}
  ): Promise<string> {
    let uploadResponse = ''

    if (!this.token) {
      return ''
    }

    try {
      const client = new storage.BlockBlobClient(
        `${this.token.url}/evidence/${
          request.id ? request.id : AuthModule.account?.localAccountId
        }_${request.name}?${this.token.sasKey}`,
        new storage.AnonymousCredential()
      )

      await client.uploadData(request.data, options)

      uploadResponse = client.url
    } catch (error) {
      Sentry.captureException(error)
      uploadResponse = ''
    }

    return uploadResponse
  }

  async getUrl(request: FileStoragePluginGetFolderOptions): Promise<string> {
    let urlResponse = ''

    await this.refreshToken()

    if (!this.token) {
      return ''
    }

    try {
      const client = new storage.BlockBlobClient(
        `${this.token.url}/evidence/${
          request.id ? request.id : AuthModule.account?.localAccountId
        }_${request.name}?${this.token.sasKey}`,
        new storage.AnonymousCredential()
      )
      const exists = await client.exists()
      urlResponse = exists ? client.url : ''
    } catch (error) {
      Sentry.captureException(error)
    }

    return urlResponse
  }
}
